import axios from "utils/axios";

export const getThrMultiplerTypes = () => {
  return axios.get("/hr/references/thrMultiplierTypes");
};

export const getThrUnderOneYearTypes = () => {
  return axios.get("/hr/references/thrUnderOneYearTypes");
};

export const getLeaveAllowanceMultiplierTypes = () => {
  return axios.get("/hr/references/leaveAllowanceMultiplierTypes");
};

export const getOvertimePayTypes = () => {
  return axios.get("/hr/references/overtimePayTypes");
};

export const getProRateTypes = () => {
  return axios.get("/hr/references/prorateTypes");
};

export const getProRateResignTypes = () => {
  return axios.get("/hr/references/prorateResignTypes");
};

export const getProRatePromotionTypes = () => {
  return axios.get("/hr/references/proratePromotionTypes");
};

export const getEmployeeStatuses = () => {
  return axios.get(`/hr/references/employeeStatuses`);
};

export const getReassignmentTypes = () => {
  return axios.get(`/hr/references/reassignmentTypes`);
};

export const getBpjsDeathProtectionTypes = () => {
  return axios.get(`/hr/references/bpjsDeathProtectionTypes`);
};

export const getBpjsWorkingAccidentProtectionTypes = () => {
  return axios.get(`/hr/references/bpjsWorkingAccidentProtectionTypes`);
};

export const getPph21CalculationMethods = () => {
  return axios.get(`/hr/references/pph21CalculationMethods`);
};

export const getSalaryPeriodTypes = () => {
  return axios.get(`/hr/references/salaryPeriodTypes`);
};

export const getSalaryPeriods = () => {
  return axios.get(`/hr/references/salaryPeriods`);
};

export const getSalaryComponentTypes = () => {
  return axios.get(`/hr/references/salaryComponentTypes`);
};

export const getA1Types = () => {
  return axios.get(`/hr/references/a1Types`);
};

export const getEmployeeTerminatedReasons = () => {
  return axios.get(`/hr/references/employeeTerminatedReasons`);
};

export const getBpjsHealthcareMultiplierTypes = () => {
  return axios.get(`/hr/references/bpjsHealthcareMultiplierTypes`);
};

export const getBpjsHealthcareCompanyResponsibilities = () => {
  return axios.get(`/hr/references/bpjsHealthcareCompanyResponsibilities`);
};

export const getBpjsHealthcareEmployeeResponsibilities = () => {
  return axios.get(`/hr/references/bpjsHealthcareEmployeeResponsibilities`);
};

export const getBpjsWorkingAccidentProtectionMultiplierTypes = () => {
  return axios.get(
    `/hr/references/bpjsWorkingAccidentProtectionMultiplierTypes`
  );
};

export const getBpjsOldAgeProtectionCompanyResponsibilities = () => {
  return axios.get(
    `/hr/references/bpjsOldAgeProtectionCompanyResponsibilities`
  );
};

export const getBpjsOldAgeProtectionEmployeeResponsibilities = () => {
  return axios.get(
    `/hr/references/bpjsOldAgeProtectionEmployeeResponsibilities`
  );
};

export const getBpjsPensionProtectionCompanyResponsibilities = () => {
  return axios.get(
    `/hr/references/bpjsPensionProtectionCompanyResponsibilities`
  );
};

export const getBpjsPensionProtectionEmployeeResponsibilities = () => {
  return axios.get(
    `/hr/references/bpjsPensionProtectionEmployeeResponsibilities`
  );
};

export const getGenders = () => {
  return axios.get(`/hr/references/genders`);
};

export const getMaritalStatuses = () => {
  return axios.get(`/hr/references/maritalStatuses`);
};

export const getBloodTypes = () => {
  return axios.get(`/hr/references/bloodTypes`);
};

export const getReligions = () => {
  return axios.get(`/hr/references/religions`);
};

export const getEducationLevels = () => {
  return axios.get(`/hr/references/educationLevels`);
};

export const getUnpaidLeaveDeductionTypes = () => {
  return axios.get(`/hr/references/unpaidLeaveDeductionTypes`);
};

export const getUnpaidLeaveDeductionNonFixedTypes = () => {
  return axios.get(`/hr/references/unpaidLeaveDeductionNonFixedTypes`);
};

export const getApprovalTypes = () => {
  return axios.get(`/hr/references/approvalTypes`);
};

export const getApprovalUserTypes = () => {
  return axios.get(`/hr/references/approvalUserTypes`);
};

export const getApprovalStageTypes = () => {
  return axios.get(`/hr/references/approvalStageTypes`);
};

export const getCitizenships = () => {
  return axios.get(`/hr/references/citizenships`);
};

export const getIdCardTypes = () => {
  return axios.get(`/hr/references/idCardTypes`);
};

export const getTaxpayerStatuses = () => {
  return axios.get(`/hr/references/taxpayerStatuses`);
};

export const getApprovalStatuses = () => {
  return axios.get(`/hr/references/approvalStatuses`);
};

export const getPayrollFormulas = () => {
  return axios.get(`/hr/references/payrollFormulas`);
};
