import axios from "utils/axios";
import { stringify } from "query-string";

export const getFingerprintConnects = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/fingerprintConnects/pagination?${query}`);
};

export const getFingerprintConnect = (id) => {
  return axios.get(`/hr/fingerprintConnects/${id}`);
};

export const getFingerprintEmployeeConnection = (params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/fingerprintConnects/${params?.id}/employeeConnection/pagination?${query}`
  );
};

export const getFingerprintUsers = (params) => {
  const query = stringify(params);
  return axios.get(
    `/hr/fingerprintConnects/${params?.id}/fingerspot/users/pagination?${query}`
  );
};

export const getFingerprintHistory = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/fingerprintConnects/histories/pagination?${query}`);
};

export const addFingerprintConnect = (data) => {
  return axios.post("/hr/fingerprintConnects", data);
};

export const syncFingerprintConnect = (data) => {
  return axios.post(`/hr/fingerprintConnects/${data?.id}/sync`, data);
};

export const loadFingerprintUsers = (data) => {
  return axios.post(
    `/hr/fingerprintConnects/${data?.id}/fingerspot/users`,
    data
  );
};

export const addFingerprintUsersInfo = (data) => {
  return axios.post(
    `/hr/fingerprintConnects/${data?.id}/fingerspot/users/info`,
    data
  );
};

export const connectFingerprintUserToEmployee = (data) => {
  return axios.post(
    `/hr/fingerprintConnects/${data?.id}/fingerspot/users/connectToEmployee`,
    data
  );
};

export const disconnectFingerprintFromEmployee = (data) => {
  return axios.delete(
    `/hr/fingerprintConnects/${data?.id}/fingerspot/users/disconnectFromEmployee`,
    { data }
  );
};

export const editFingerprintConnect = (data) => {
  return axios.put(`/hr/fingerprintConnects/${data?.id}`, data);
};

export const deleteFingerprintConnect = (id) => {
  return axios.delete(`/hr/fingerprintConnects/${id}`);
};
