import { useMutation, useQuery } from "react-query";
import {
  addSsoDomain,
  deleteSsoDomain,
  generateSpSso,
  getSsoConfiguration,
  getSsoDomain,
  saveSsoConfiguration,
  sendCodeSsoDomain,
  updateSsoConfiguration,
  verifySsoDomain,
} from "apis/sso";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export const useSsoDomain = ({ options = {} }) => {
  return useQuery(
    ["sso-domain"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSsoDomain();

        response = data;
      } catch (error) {
        message.error(
          error?.data?.message || "Failed to load data from server!"
        );
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.data?.message || "Failed to load data from server!"
        ),
      ...options,
    }
  );
};

export const useAddSsoDomainMutation = () => {
  const { t } = useTranslation();
  return useMutation((payload) => addSsoDomain(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useSendCodeSsoDomainMutation = () => {
  const { t } = useTranslation();
  return useMutation((payload) => sendCodeSsoDomain(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useVerifySsoDomainMutation = () => {
  const { t } = useTranslation();
  return useMutation((payload) => verifySsoDomain(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteSsoDomainMutation = () => {
  const { t } = useTranslation();
  return useMutation((payload) => deleteSsoDomain(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useSsoConfiguration = ({ options = {} } = {}) => {
  return useQuery(
    ["sso-configuration"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await getSsoConfiguration();

        response = data;
      } catch (error) {
        message.error(
          error?.data?.message || "Failed to load data from server!"
        );
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.data?.message || "Failed to load data from server!"
        ),
      ...options,
    }
  );
};

export const useGenerateSpSso = ({ options = {} } = {}) => {
  return useQuery(
    ["generate-sp-sso"],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await generateSpSso();

        response = data;
      } catch (error) {
        message.error(
          error?.data?.message || "Failed to load data from server!"
        );
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.data?.message || "Failed to load data from server!"
        ),
      ...options,
    }
  );
};

export const useSaveSsoConfigurationMutation = () => {
  return useMutation(saveSsoConfiguration);
};

export const useUpdateSsoConfigurationMutation = () => {
  return useMutation(updateSsoConfiguration);
};
