const transType = {
  EMPLOYEE: 1, //karyawan
  TAXPAYER_STATUS: 2, //Status Wajib pajak
  CAREER: 3, //career
  PAYROLL: 4, //payroll
  PRESENCE: 5, //kehadiran
  WORK_SCHEDULE: 6, //jadwal kerja tetap
  SHIFT_SCHEDULE: 7, //jadwal kerja shift
  ATTENDANCE: 8, //Kehadiran
  LEAVE: 9, //izin cuti
  CLIENT_VISIT: 10, //kunjungan client
  PAYROLL_SETTING: 11, //pengaturan payroll (not fix)
  APPROVAL: 12, //approval
  TIMELINE: 13, //timeline dan pengumuman
  BILLING: 14, //setting billing
  ORGANIZATION_STRUCTURE: 15, //struktur organisai
  JOB_LEVEL: 16, // pangkat
  JOB_POSITION: 17, //job position
  COMPANY_POLICY: 18, //Peraturan Perusahaan
  CALENDAR: 19, //Kalender
  SALARY_SLIP_COMPONENT: 20, //salary slip component pendaptan dan potongan
  SALARY_SLIP: 21, //Pengaturan Payroll Slip Gaji
  TAX_WITHHOLDING: 22, //Pemotongan Pajak
  COMPANY_ACCOUNT: 23, //Pengaturan Rekening Perusahaan
  SCHEDULE_PATTERN: 24, //Pengaturan Jadwal Kerja
  SHIFT: 25, //shift
  PRESENCE_APPLICATION_VALIDATION: 26, //validasi aplikasi presensi
  ATTENDANCE_LOCATION: 27, //Pengaturan Lokasi Kantor
  APPROVAL_RULE: 28, //Pengaturan Approval Rule
  EMAIL_NOTIFICATION_TEMPLATE: 29, //Pengaturan template notifikasi email
  PUSH_NOTIFICATION_TEMPLATE: 30, //Pengaturan template notifikasi aplikasi mobile
  ANNUAL_LEAVE_PERIOD: 31, //Periode cuti tahunan
  CASH_RECEIPT: 32, //kasbon
  REIMBURSEMENT: 33, //reimbursement
  TEAM_STATUS: 34,
  LOCATION_TRACKING_RULE: 38, //pelacakan lokasi
  LOCATION_TRACKING: 39, //lokasi
  CLIENT_MANAGEMENT: 40, //manajemen klien
  OVERTIME_COMPONENT_TYPE: 41, //tipe komponen lembur
  DETAIL_REIMBURSEMENT: 42, //detail reimbursement
  PAYROLL_PRORATE: 45, //payroll prorate rule
  TASK: 46, //task
  ACCOUNT_MAPPING_COA: 35, //pemetaan akun coa
  INTEGRATION_APP_OPEN_API: 68, //integrasi app open api
  EMPLOYEE_CREATED: 1001, //karyawan telah dibuat
  EMPLOYEE_CAREER: 1002, //karir karyawan
  EMPLOYEE_PAYROLL: 1003, //payroll karyawan
  EMPLOYEE_IDENTITY: 1004, //Informasi Peribadi
  EMPLOYEE_CONTACT: 1005, //Informasi Kontak
  EMPLOYEE_PERSONAL_EDUCATION: 1006, //Pendidikan Terakhir
  EMPLOYEE_ATTACHMENT: 1007, //Lampiran karyawan
  EMPLOYEE_WORK_START_DATE: 1008, //Masa Kerja Karyawan
  EMPLOYEE_NPWP: 1009, //NPWP karyawan
  EMPLOYEE_BPJS: 1010, //BPJS Karyawan
  EMPLOYEE_BANK_ACCOUNT: 1011, //Rekening Bank Karyawan
  LEAVE_ATTACHMENT: 9001, //Lampiran izin cuti
  PAYROLL_SLIP: 11001, //Payroll Slip
  PAYROLL_SLIP_STATUS: 11002, //payroll slip status
  PAYROLL_SLIP_CONFIRMATION: 11003, //konfirmasi payroll slip
  TIMELINE_ATTACHMENT: 13001, //Timeline attachment
  TIMELINE_COMMENT: 13002, // komen timeline
  CASH_RECEIPT_PAYMENT: 32001, //Pembayaran Kasbon
  CASH_RECEIPT_PAYROLL: 32002, //Kasbon dari payroll
  CASH_RECEIPT_PAYROLL_PAYMENT: 32003, //Pembayaran Kasbon dari payroll
  REIMBURSE_PAYMENT: 33001, //Pembayaran Reimbursement
  REIMBURSE_ATTACHMENT: 33002, //Lampiran Reimbursement
  APP_SETTING: 66001, //Pengaturan Aplikasi
  OVERTIME_PAY_SETTING: 66002, //Pengaturan Payroll Lembur
  THR_SETTING: 66003, //Pengaturan THR
  OVERTIME_EXPORT_SETTING: 66004, //Pengaturan Export Lembur
  LEAVE_ALLOWANCE_SETTING: 66005, //Pengaturan Uang Lembur
  ANNUAL_LEAVE_SETTING: 66006, //Pengaturan Cuti Tahunan
  SICK_LEAVE_SETTING: 66007, //Pengaturan Cuti Sakit
  PRORATE_SETTING: 66008, //Pengaturan Prorate
  BPJS_SETTING: 66009, //Pengaturan BPJS
  UNPAID_LEAVE_SETTING: 66010, //Pengaturan Cuti Tanpa Gaji
  PPH21_SETTING: 66011, //Pengaturan PPh21
  BPJS_HEALTHCARE_SETTING: 66012, //Pengaturan BPJS Kesehatan
  BPJS_BASIC_SETTING: 66013, //Pengaturan BPJS Ketenagakerjaan
  FINANCE_SETTING: 66014, //Pengaturan Keuangan
  FINANCE_TAG_SETTING: 66015, //Pengaturan Tag Keuangan
  FINANCE_TAG_RULES_SETTING: 66016, //Pengaturan Aturan Tag Keuangan
  ATTENDANCE_LOG_RULES_SETTING: 66017, //Pengaturan Aturan Log Kehadiran
  APPLICATIONS_MENU_SETTING: 66018, //Pengaturan Menu Aplikasi
  REMINDER_SETTING: 66019, //Pengaturan Pengingat
  THEME_COLORS_SETTING: 66020, //Pengaturan Warna Tema
  TIMELINE_SETTING: 66022, //Pengaturan Timeline
  CASH_RECEIPT_SETTING: 66023, //Pengaturan Kasbon
  REIMBURSEMENT_SETTING: 66024, //Pengaturan Reimbursement
  COMPANY_ESS_NOTIFICATION_SETTING: 66025, //Pengaturan Notifikasi ESS Perusahaan
  COMPANY_TEAMS_SETTING: 66026, //Pengaturan Tim Perusahaan
  COMPANY_ESS_POST_SETTING: 66027, //Pengaturan Posting ESS Perusahaan
  LOCATION_TRACKING_RULE_SETTING: 66029, //Pengaturan Aturan Pelacakan Lokasi
  NUMBERS_SETTING: 66030, //Pengaturan Angka
  TASK_SETTING: 66031, //Pengaturan Tugas
  ROUNDING_SETTING: 66032, //Pengaturan Pembulatan
  SEVERANCES_SETTING: 66033, //Pengaturan Pesangon
  COA_ACCOUNT_MAPPING_PER_SALARY_COMPONENT: 66034, //Pemetaan Akun COA per Komponen Gaji
  COA_PAYMENT_ACCOUNT_MAPPING_PER_BANK: 66035, //Pemetaan Akun Pembayaran per Bank
  EMPLOYEE_TEAMS_SETTING: 66037, //Pengaturan Tim Karyawan
  IMPORT_MULTIPLIER_BPJS_PER_EMPLOYEE: 66038, //Import Multiplier BPJS Payroll
  TASK_DISPLAY_SETTING: 66040, //Pengaturan Tampilan Tugas
  EMPLOYEE_ESS_POST_SETTING: 66041, //Pengaturan Posting ESS Karyawan
  SSO_SETTING: 66042, //Pengaturan SSO
  SSO_HR_DOMAIN: 6604201, //Pengaturan Domain SSO HR
  SSO_HR_CONFIG: 6604202, //Pengaturan Konfigurasi SSO HR
};

const locale = {
  ID_ID: "id-ID",
  EN_US: "en-US",
};

const slipSalaryComponentType = {
  BASIC_SALARY: 1,
  THR: 2,
  OVERTIME_PAY: 3,
  FIXED: 4,
  BASED_ON_ATTENDANCE: 5,
  BASED_ON_OUTPUT: 6,
  MANUAL: 7,
  FIXED_BASED_ON_OUTPUT: 8,
};

const componentType = {
  INCOMES: 1,
  DEDUCTIONS: 2,
};

const taxCalculationMethod = {
  GROSS: 1,
  GROSS_UP: 2,
  NETT: 3,
};

const payrollPaymentType = {
  PAYROLL_SLIP: "payroll-slip",
  PAYMENT_INCOME: "payment-income",
  PAYMENT_DEDUCTION: "payment-deduction",
  CASH_RECEIPT_INCOME: "cash-receipt-income",
  CASH_RECEIPT_PAYMENT: "cash-receipt-payment",
  DELETE_SLIP_PAYMENT: "delete-slip-payment",
};

const stepEmployee = {
  PERSONAL: 1,
  CAREER: 2,
  PAYROLL: 3,
};

const tableEmployee = {
  PERSONAL: 1,
  CONTACT: 2,
  CAREER: 3,
  PAYROLL: 4,
  ESS: 5,
};

const leaveType = {
  ANNUAL_LEAVE: "annual-leave",
  SPECIAL_LEAVE: "special-leave",
  SICK_LEAVE: "sick-leave",
  PERMISSION_LEAVE: "permission-leave",
  UNPAID_LEAVE: "unpaid-leave",
  REMAINING_LEAVE: "remaining-leave",
  LEAVE_HISTORY: "leave-history",
};

const leaveTypeId = {
  ANNUAL_LEAVE: 1,
  SPECIAL_LEAVE: 2,
  UNPAID_LEAVE: 3,
  PERMISSION_LEAVE: 4,
  SICK_LEAVE: 5,
};

const approvalType = {
  ANNUAL_LEAVE: "annual-leave",
  SPECIAL_LEAVE: "special-leave",
  SICK_LEAVE: "sick-leave",
  PERMISSION_LEAVE: "permission-leave",
  UNPAID_LEAVE: "unpaid-leave",
  REIMBURSEMENT: "reimbursement",
  CASH_RECEIPT: "cash-receipt",
};

const approvalTypeId = {
  ANNUAL_LEAVE: 1,
  SPECIAL_LEAVE: 2,
  UNPAID_LEAVE: 3,
  PERMISSION_LEAVE: 4,
  SICK_LEAVE: 5,
  CASH_RECEIPT: 7,
  REIMBURSE: 8,
  PERSONAL_DATA_CHANGE: 9,
};

const cashReceiptType = {
  BALANCE: "balance",
  WAITING_APPROVAL: "waiting-approval",
  APPROVED: "approved",
  REJECTED: "rejected",
  HISTORY: "history",
  PAYMENT: "payment",
};

const statusCashReceipt = {
  WAITING_APPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
  PAID: 4,
  PAID_OFF: 5,
  DUE_DATE: 6,
};

const statusLeave = {
  WAITING_APPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
};

const statusReimburse = {
  WAITING_APPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
  PAID: 4,
};

const statusApproval = {
  WAITING_APPROVAL: 1,
  APPROVED: 2,
  REJECTED: 3,
};

const statusTask = {
  QUEUE: 1,
  ON_GOING: 2,
  DONE: 3,
};

const priorityTask = {
  LOW: 1,
  MEDIUM: 2,
  HEIGHT: 3,
};

const attendanceLogTypeConst = {
  PRESENCE: "presence",
  OVERTIME: "overtime",
  BREAK: "break",
  CLIENT_VISIT: "client_visit",
};

const fingerprintStatus = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
  ERROR: 2,
};

const taskActivityType = {
  DEFAULT: 1,
  CLIENT_VISIT_IN: 2,
  CLIENT_VISIT_OUT: 3,
  CHANGE_STATUS: 4,
  CHANGE_APPROVAL: 5,
};

const dashboardType = {
  AGE: "age",
  RELIGION: "religion",
  EDUCATION: "education",
  GENDER: "gender",
  MARITAL_STATUS: "marital_status",
  NUMBER_OF_EMPLOYEE_PER_MONTH: "number_of_employee_per_month",
  NUMBER_OF_EMPLOYEES_PER_ORGANIZATION: "number_of_employees_per_organization",
  YEARS_OF_WORK: "years_of_work",
  TOTAL_SALARY_OF_EMPLOYEE: "total_salary_of_employee",
  SALARY_PER_ORGANIZATION: "salary_per_organization",
  EMPLOYEE_STATUS: "employee_status",
  TOTAL_PERSONALIA_BASED_ON_SALARY: "total_personalia_based_on_salary",
  TOTAL_SALARY_PER_COMPONENT: "total_salary_per_component",
  NUMBER_OF_ATTENDANCE_STATUS_EMPLOYEE: "number_of_attendance_status_employee",
  LIST_OF_LATE_EMPLOYEES: "list_of_late_employees",
  LIST_OF_EMPLOYEES_LEAVE: "list_of_employees_leave",
  ATTENDANCE: "attendance",
  EMPLOYEE_OF_ATTENDANCE_RATING_STATISTICS:
    "employee_of_attendance_rating_statistics",
  EMPLOYEE_TERMINATION: "employee_termination",
  EVENT_CALENDAR: "event_calendar",
};

const randomColors = [
  "#6FCF97",
  "#F9AD8D",
  "#E4D1D3",
  "#F1E6D1",
  "#ACDCEE",
  "#A5F0E4",
  "#D9F489",
  "#FFA1AC",
  "#FFE0A3",
  "#F47645",
  "#2D9CDB",
  "#1D4ED8",
  "#FAAD14",
];

const dataQuotes = [
  // {
  //   name: "Herlin Dwi Y",
  //   company: "CEO PT. JC Indonesia",
  //   quotes:
  //     "Bayar gajian, hitung pajak Pph21, sampe urusan reimburse ga lagi repot. Jadi ga perlu lagi banyak-banyak punya tim HRD.",
  //   image:
  //     "https://kledocontent.s3.ap-southeast-1.amazonaws.com/jcindonesia.jpg",
  // },
  // {
  //   name: "Mariana R. Afianti",
  //   company: "Co-Founder Kerjoo",
  //   quotes:
  //     "Enak nih pantau hutang dan cicilan karyawan ga lagi bikin stress. Pengajuan cuti, lembur, dan absensi juga serba otomatis. Mantab deh!",
  //   image: "https://kledocontent.s3.ap-southeast-1.amazonaws.com/kerjoo.jpg",
  // },
  // {
  //   name: "Windy",
  //   company: "PT MOTORINDO SUKSES SEJAHTERA",
  //   quotes:
  //     "Kledo sangat mudah dipahami! Kalau kesulitan selalu didampingi. Jadi merasa punya tim IT sendiri",
  //   image: "https://kledo.com/wp-content/testimonials/pt-motorindo.jpg",
  // },
  {
    name: "Khamim Aris Prasetiyo",
    company: "HR Pandumedia",
    employee: "60 Karyawan ",
    quotes:
      "Gajihub membantu kami dalam proses otomatisasi rekap absen harian dan lembur karyawan. Tim HR jadi terbantu banget karena proses kerja menjadi lebih efisien dan meminimalisir proses manual yang makan banyak waktu.",
    image: "/resources/images/testimoni_1.jpg",
  },
  {
    name: "Rachmadanty Mujah Hartika",
    company: "HR PT PELANGI TERBANG INDONESIA",
    employee: "200 Karyawan ",
    quotes:
      "Semenjak pake GajiHub, karyawan bisa mengajukan izin cuti secara fleksibel dan meningkatkan produktivitas mereka. Sekarang HR tidak perlu repot rekap satu persatu dan ini sangat membantu tim kami.",
    image: "/resources/images/testimoni_2.jpg",
  },
  {
    name: "L. Anis Prihatsiwi",
    company: "HR PT JC INDONESIA",
    employee: "76 Karyawan ",
    quotes:
      "Sangat membantu HRD banget, mulai dari presensi, payroll, reimburse cuti, bahkan pinjaman karyawan sekalipun sangat mudah diolah dan dipantau, sehingga membuat pekerjaan lebih efektif dan efisien. Jadi lebih produktif deh kerjanya. GajiHub Keren!",
    image: "/resources/images/testimoni_3.jpg",
  },
];

const colorsMenu = [
  "#FDE3D9",
  "#ACDCEE",
  "#D9F489",
  "#FFE0A3",
  "#A5F0E4",
  "#E4D1D3",
  "#FFA1AC",
  "#F1E6D1",
  "#6FCF97",
  "#FDDECE",
];

const colorsIcon = [
  "#F47645",
  "#2D9CDB",
  "#219653",
  "#EB5757",
  "#2F80ED",
  "#9B51E0",
  "#FFFFFF",
  "#F47645",
  "#FFFFFF",
  "#EA580C",
];

const colorsShortcut = [
  "bg-blue-400",
  "bg-purple-400",
  "bg-orange-400",
  "bg-orange-200",
  "bg-pink-400",
  "bg-yellow-400",
  "bg-red-400",
  "bg-green-400",
];

const colorsPrimary = [
  "#64748B",
  "#6B7280",
  "#71717A",
  "#737373",
  "#78716C",
  "#F97316",
  "#F59E0B",
  "#EAB308",
  "#84CC16",
  "#22C55E",
  "#10B981",
  "#14B8A6",
  "#06B6D4",
  "#0EA5E9",
  "#3B82F6",
  "#6366F1",
  "#8B5CF6",
  "#A855F7",
  "#D946EF",
  "#EC4899",
  "#E53F6A",
  "#F43F5E",
  "#EF4444",
];

const applicationMenu = [
  {
    id: 1,
    name: "mobile_menu_attendance_enabled",
    title: "mobileMenu.attendance",
    icon: "attendance",
  },
  {
    id: 2,
    name: "mobile_menu_leave_enabled",
    title: "mobileMenu.leave",
    icon: "leave",
  },
  {
    id: 3,
    name: "mobile_menu_payroll_enabled",
    title: "mobileMenu.salary",
    icon: "salary",
  },
  {
    id: 4,
    name: "mobile_menu_calendar_enabled",
    title: "mobileMenu.calendar",
    icon: "calendar",
  },
  // {
  //   id: 5,
  //   name: "mobile_menu_report_enabled",
  //   title: "Report",
  //   icon: "report",
  // },
  // {
  //   id: 6,
  //   name: "mobile_menu_history_enabled",
  //   title: "History",
  //   icon: "history",
  // },
  {
    id: 7,
    name: "mobile_menu_company_enabled",
    title: "mobileMenu.company",
    icon: "company",
  },
  // {
  //   id: 8,
  //   name: "mobile_menu_schedule_enabled",
  //   title: "mobileMenu.schedule",
  //   icon: "schedule",
  // },
  {
    id: 9,
    name: "mobile_menu_approval_enabled",
    title: "mobileMenu.approval",
    icon: "check",
  },
  {
    id: 10,
    name: "mobile_menu_team_enabled",
    title: "mobileMenu.teams",
    icon: "team",
  },
  {
    id: 11,
    name: "mobile_menu_cash_receipt_enabled",
    title: "mobileMenu.cash_receipt",
    icon: "receipt",
  },
  {
    id: 12,
    name: "mobile_menu_reimbursement_enabled",
    title: "mobileMenu.reimbursement",
    icon: "reimbursement",
  },
  {
    id: 13,
    name: "mobile_menu_task_enabled",
    title: "mobileMenu.task",
    icon: "task",
  },
];

const shortcutMenu = [
  {
    id: 1,
    name: "mobile_shortcut_menu_client_visit_end_enabled",
    title: "mobileMenu.visit_out",
    icon: "visit_end",
    rotate: true,
  },
  {
    id: 2,
    name: "mobile_shortcut_menu_client_visit_start_enabled",
    title: "mobileMenu.visit_in",
    icon: "visit_start",
  },
  {
    id: 8,
    name: "mobile_shortcut_menu_overtime_end_enabled",
    title: "mobileMenu.overtime_out",
    icon: "overtime_end",
  },
  {
    id: 7,
    name: "mobile_shortcut_menu_overtime_start_enabled",
    title: "mobileMenu.overtime_in",
    icon: "overtime_start",
    rotate: true,
  },
  {
    id: 3,
    name: "mobile_shortcut_menu_break_end_enabled",
    title: "mobileMenu.break_end",
    icon: "break_end",
  },
  {
    id: 4,
    name: "mobile_shortcut_menu_break_start_enabled",
    title: "mobileMenu.break_start",
    icon: "break_start",
  },
  {
    id: 5,
    name: "mobile_shortcut_menu_presence_end_enabled",
    title: "mobileMenu.presence_out",
    icon: "presence_out",
  },
  {
    id: 6,
    name: "mobile_shortcut_menu_presence_start_enabled",
    title: "mobileMenu.presence_in",
    icon: "presence_in",
    rotate: true,
  },
];

const leaveTypes = [
  {
    id: leaveTypeId.ANNUAL_LEAVE,
    className: "text-[#2D9CDB]",
    settingName: "mobile_submenu_leave_annual_leave_enabled",
  },
  {
    id: leaveTypeId.SPECIAL_LEAVE,
    className: "text-[#BB6BD9]",
    settingName: "mobile_submenu_leave_special_leave_enabled",
  },
  {
    id: leaveTypeId.UNPAID_LEAVE,
    className: "text-[#6FCF97]",
    settingName: "mobile_submenu_leave_unpaid_leave_enabled",
  },
  {
    id: leaveTypeId.PERMISSION_LEAVE,
    className: "scale-x-[-1] text-[#6FCF97]",
    settingName: "mobile_submenu_leave_permit_enabled",
  },
  {
    id: leaveTypeId.SICK_LEAVE,
    className: "text-[#F2994A]",
    settingName: "mobile_submenu_leave_sick_enabled",
  },
];

const calendarConst = {
  JOINT_LEAVE: 12,
};

const attendanceLogConst = {
  CLOCK_IN: 1,
  CLOCK_OUT: 2,
  BREAK_IN: 3,
  BREAK_OUT: 4,
  OVERTIME_IN: 5,
  OVERTIME_OUT: 6,
  CLIENT_VISIT_IN: 7,
  CLIENT_VISIT_OUT: 8,
};

const attendanceLogTypes = [
  {
    id: attendanceLogConst.CLOCK_IN,
    name: "mobileMenu.presence_in",
    type: "presence",
  },
  {
    id: attendanceLogConst.CLOCK_OUT,
    name: "mobileMenu.presence_out",
    type: "presence",
  },
  {
    id: attendanceLogConst.BREAK_IN,
    name: "mobileMenu.break_start",
    type: "break",
  },
  {
    id: attendanceLogConst.BREAK_OUT,
    name: "mobileMenu.break_end",
    type: "break",
  },
  {
    id: attendanceLogConst.OVERTIME_IN,
    name: "mobileMenu.overtime_in",
    type: "overtime",
  },
  {
    id: attendanceLogConst.OVERTIME_OUT,
    name: "mobileMenu.overtime_out",
    type: "overtime",
  },
  {
    id: attendanceLogConst.CLIENT_VISIT_IN,
    name: "mobileMenu.visit_in",
    type: "client_visit",
  },
  {
    id: attendanceLogConst.CLIENT_VISIT_OUT,
    name: "mobileMenu.visit_out",
    type: "client_visit",
  },
];

export {
  locale,
  slipSalaryComponentType,
  componentType,
  stepEmployee,
  tableEmployee,
  leaveType,
  leaveTypeId,
  statusLeave,
  payrollPaymentType,
  randomColors,
  dataQuotes,
  dashboardType,
  attendanceLogTypeConst,
  colorsIcon,
  colorsMenu,
  colorsShortcut,
  colorsPrimary,
  shortcutMenu,
  applicationMenu,
  taxCalculationMethod,
  cashReceiptType,
  statusCashReceipt,
  statusReimburse,
  statusTask,
  priorityTask,
  approvalType,
  approvalTypeId,
  transType,
  leaveTypes,
  calendarConst,
  fingerprintStatus,
  attendanceLogConst,
  attendanceLogTypes,
  taskActivityType,
  statusApproval,
};
