import React, { useCallback, useEffect, useState } from "react";
import { Tag, Select, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import _ from "lodash";

import useFormatting from "hooks/useFormatting";
import DatePicker from "components/UI/DatePicker";
import "./index.css";

// const { Text } = Typography;

const getTimestamp = (value) => {
  return value.startOf("day").valueOf();
};

const MultipleDatePicker = ({
  value: selectedDate,
  index,
  max, //initial limit multiple picker
  noLimit,
  onChange,
  format,
  selectProps,
  datePickerProps,
  disabled,
  isHalfDay,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { formatDate } = useFormatting();

  const onValueChange = useCallback(
    (date) => {
      if (selectedDate.length <= max || noLimit) {
        const t = getTimestamp(date);
        const index = selectedDate.map((e) => dayjs(e).valueOf()).indexOf(t);
        const clone = _.clone(selectedDate);
        if (index > -1) {
          clone.splice(index, 1);
        } else {
          if (selectedDate.length < max) {
            clone.push(t);
          }
        }
        onChange && onChange(clone);
      }
    },
    [max, noLimit, onChange, selectedDate]
  );

  const handleLimit = useCallback(() => {
    if (max >= selectedDate.length || noLimit) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [max, selectedDate, noLimit]);

  const dateRender = (currentDate) => {
    const formattedDate = (selectedDate || []).map((e) =>
      getTimestamp(dayjs(e))
    );
    const isSelected = formattedDate.indexOf(getTimestamp(currentDate)) > -1;
    return (
      <div
        className={"ant-picker-cell-inner"}
        style={
          isSelected
            ? {
                position: "relative",
                zIndex: 2,
                display: "inlineBlock",
                width: "24px",
                height: "22px",
                lineHeight: "22px",
                backgroundColor: "#1890ff",
                color: "#fff",
                margin: "auto",
                borderRadius: "2px",
                transition: "background 0.3s, border 0.3s",
              }
            : {}
        }
      >
        {currentDate.date()}
      </div>
    );
  };

  const renderTag = ({ value, onClose }) => {
    const handleClose = () => {
      onClose();
      onChange && onChange(selectedDate.filter((t) => t !== value));
    };
    return (
      <Tag
        key={value}
        className="bg-blue2 text-white px-3 py-1 rounded-full font-semibold"
        closeIcon={
          !disabled ? (
            <CloseOutlined className="text-white text-xs ml-1 hover:opacity-70 hover:cursor-pointer" />
          ) : null
        }
        onClose={!disabled ? handleClose : null}
        closable={!disabled}
      >
        {formatDate(dayjs(value))}
      </Tag>
    );
  };

  const handleClickOutside = useCallback(
    (event) => {
      const dropdown = document.getElementsByClassName(
        `multipleDropdownClassName-${index}`
      )[0];
      if (dropdown && !dropdown.contains(event.target)) {
        setOpen(false);
      }
    },
    [index]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <Row className="border-b border-gray-300">
        <Col span={22}>
          <Select
            {...selectProps}
            disabled={disabled}
            mode="multiple"
            value={selectedDate}
            onClear={() => onChange && onChange([])}
            tagRender={renderTag}
            variant="borderless"
            open={open}
            onFocus={handleLimit}
            popupMatchSelectWidth={false}
            suffixIcon={null}
            popupClassName={`multipleDropdownClassName-${index}`}
            dropdownStyle={{ height: "270px", width: "280px", minWidth: "0" }}
            dropdownRender={() => {
              return (
                <DatePicker
                  {...datePickerProps}
                  disabled={disabled}
                  format={(value) => ""}
                  onChange={onValueChange}
                  value={""}
                  showToday={false}
                  open
                  cellRender={dateRender}
                  style={{
                    ...datePickerProps.style,
                    visibility: "hidden",
                  }}
                  getPopupContainer={() =>
                    document.getElementsByClassName(
                      `multipleDropdownClassName-${index}`
                    )[0]
                  }
                />
              );
            }}
          />
        </Col>
        <Col span={2}>
          <div className="flex items-center justify-end h-full">
            <Tag className="bg-primary text-white px-3 py-1 rounded-full font-semibold">
              {`${isHalfDay ? selectedDate.length / 2 : selectedDate.length} ${
                selectedDate.length > 1 ? t("employee.day") : t("employee.days")
              }`}
            </Tag>
          </div>
        </Col>
      </Row>
      {/* {!noLimit && (
        <div className="flex justify-end mt-2">
          {loading ? (
            <Spin indicator={<LoadingOutlined spin />} />
          ) : (
            <Text className="text-right text-xs">{`${t(
              "employee.max"
            )} ${max} ${
              max > 1 ? t("employee.day") : t("employee.days")
            }`}</Text>
          )}
        </div>
      )} */}
    </>
  );
};

MultipleDatePicker.propTypes = {
  index: PropTypes.number,
  value: PropTypes.array,
  max: PropTypes.number,
  noLimit: PropTypes.bool,
  onChange: PropTypes.func,
  format: PropTypes.string,
  selectProps: PropTypes.object,
  datePickerProps: PropTypes.object,
  isHalfDay: PropTypes.bool,
};

MultipleDatePicker.defaultProps = {
  index: 0,
  value: [],
  max: 10,
  noLimit: false,
  format: "DD/MM/YYYY",
  selectProps: {},
  datePickerProps: {},
  isHalfDay: false,
};

export default MultipleDatePicker;
