import { memo, useMemo } from "react";
import { Dropdown } from "antd";
import {
  DownloadOutlined,
  DownOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ExportMenu = ({ id, title, icon, onExport, ...props }) => {
  const { t } = useTranslation();

  const menuItems = useMemo(() => {
    const items = [
      {
        key: 1,
        icon: <FileExcelOutlined />,
        onClick: (e) => {
          if (
            props?.onOpenChange &&
            typeof props?.onOpenChange === "function"
          ) {
            props?.onOpenChange(false);
          }
          onExport("csv");
        },
        label: "CSV",
      },
      {
        key: 2,
        icon: <FileExcelOutlined />,
        onClick: (e) => {
          if (
            props?.onOpenChange &&
            typeof props?.onOpenChange === "function"
          ) {
            props?.onOpenChange(false);
          }
          onExport("xls");
        },
        label: "XLSX",
      },
    ];
    return items;
  }, [onExport, props]);

  return (
    <div>
      <Dropdown.Button
        id={id}
        icon={icon}
        trigger={["click"]}
        menu={{ items: menuItems, className: "p-0" }}
        overlayClassName="min-w-[130px]"
        {...props}
      >
        <div className="flex items-center justify-center">
          <DownloadOutlined className="mr-2 mt-[2px]" size={16} />
          {title ? title : t("button.export")}
        </div>
      </Dropdown.Button>
    </div>
  );
};

ExportMenu.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  onExport: PropTypes.func,
  id: PropTypes.string,
};

ExportMenu.defaultProps = {
  icon: <DownOutlined />,
  id: "btn-export",
  onExport: () => {},
};

export default memo(ExportMenu);
