import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import * as api from "apis/fingerprintConnect";

export const useFingerprintConnects = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["fingerprintConnects", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintConnects(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFingerprintConnectsSuggestion = (
  payload = {},
  options = {}
) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["fingerprintConnects/suggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintConnects({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFingerprintConnect = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["fingerprintConnect", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintConnect(payload?.id);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFingerprintEmployeeConnection = (
  payload = {},
  options = {}
) => {
  const { t } = useTranslation();
  return useQuery(
    ["fingerprintConnect/employee-connection", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintEmployeeConnection(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFingerprintEmployeeConnectionSuggestion = (
  payload = {},
  options = {}
) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["fingerprintConnect/employee-connection/suggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintEmployeeConnection({
          page: pageParam,
          ...payload,
        });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFingerprintHistory = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["fingerprintHistory/pagination", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintHistory({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFingerprintUsers = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useQuery(
    ["fingerprintConnect/users", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintUsers(payload);
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useFingerprintUsersSuggestion = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["fingerprintConnect/users/suggestion", payload],
    async ({ pageParam = 0 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getFingerprintUsers({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      keepPreviousData: true,
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useAddFingerprintConnect = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addFingerprintConnect(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useSyncFingerprintConnect = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.syncFingerprintConnect(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useLoadFingerprintUsers = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.loadFingerprintUsers(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddFingerprintUsersInfo = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addFingerprintUsersInfo(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useConnectFingerprintUserToEmployee = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.connectFingerprintUserToEmployee(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};

export const useDisconnectFingerprintFromEmployee = () => {
  const { t } = useTranslation();
  return useMutation(
    (payload) => api.disconnectFingerprintFromEmployee(payload),
    {
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error) => message.error(t("error.failed_save_data_to_server")),
    }
  );
};

export const useEditFingerprintConnect = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editFingerprintConnect(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteFingerprintConnect = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteFingerprintConnect(payload?.id), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};
