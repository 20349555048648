import { useQuery } from "react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import * as api from "apis";

export function useThrMultiplerTypes(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "thrMultiplerTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getThrMultiplerTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
      ...options,
    }
  );
}

export function useThrUnderOneYearTypes(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "thrUnderOneYearTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getThrUnderOneYearTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
      ...options,
    }
  );
}

export function useLeaveAllowanceMultiplerTypes(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "leaveAllowanceMultiplerTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getLeaveAllowanceMultiplierTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
      ...options,
    }
  );
}

export function useOvertimePayTypes(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "overtimePayTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getOvertimePayTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
      ...options,
    }
  );
}

export function useProRateTypes(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "proRateTypes",
    async () => {
      let response;
      try {
        const {
          data: {
            data: { data },
          },
        } = await api.getProRateTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
      ...options,
    }
  );
}

export function useProRatePromotionTypes(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "proRatePromotionTypes",
    async () => {
      let response;
      try {
        const {
          data: {
            data: { data },
          },
        } = await api.getProRatePromotionTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
      ...options,
    }
  );
}

export function useProRateResignTypes(options = {}) {
  const { t } = useTranslation();
  return useQuery(
    "proRateResignTypes",
    async () => {
      let response;
      try {
        const {
          data: {
            data: { data },
          },
        } = await api.getProRateResignTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
      ...options,
    }
  );
}

export function useEmployeeStatuses() {
  const { t } = useTranslation();
  return useQuery(
    "employeeStatuses",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeeStatuses();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useReassignmentTypes() {
  const { t } = useTranslation();
  return useQuery(
    "reassignmentTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getReassignmentTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsDeathProtectionTypes() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsDeathProtectionTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsDeathProtectionTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsWorkingAccidentProtectionTypes() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsWorkingAccidentProtectionTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsWorkingAccidentProtectionTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function usePph21CalculationMethods() {
  const { t } = useTranslation();
  return useQuery(
    "pph21CalculationMethods",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPph21CalculationMethods();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useSalaryPeriodTypes() {
  const { t } = useTranslation();
  return useQuery(
    "salaryPeriodTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalaryPeriodTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useSalaryPeriods() {
  const { t } = useTranslation();
  return useQuery(
    "salaryPeriods",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalaryPeriods();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useSalaryComponentTypes() {
  const { t } = useTranslation();
  return useQuery(
    "salaryComponentTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getSalaryComponentTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useA1Types() {
  const { t } = useTranslation();
  return useQuery(
    "a1Types",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getA1Types();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useEmployeeTerminatedReasons() {
  const { t } = useTranslation();
  return useQuery(
    "employeeTerminatedReasons",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEmployeeTerminatedReasons();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsHealthcareMultiplierTypes() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsHealthcareMultiplierTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsHealthcareMultiplierTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsHealthcareCompanyResponsibilities() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsHealthcareCompanyResponsibilities",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsHealthcareCompanyResponsibilities();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsHealthcareEmployeeResponsibilities() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsHealthcareEmployeeResponsibilities",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsHealthcareEmployeeResponsibilities();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsWorkingAccidentProtectionMultiplierTypes() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsWorkingAccidentProtectionMultiplierTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsWorkingAccidentProtectionMultiplierTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsOldAgeProtectionCompanyResponsibilities() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsOldAgeProtectionCompanyResponsibilities",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsOldAgeProtectionCompanyResponsibilities();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsOldAgeProtectionEmployeeResponsibilities() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsOldAgeProtectionEmployeeResponsibilities",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsOldAgeProtectionEmployeeResponsibilities();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsPensionProtectionCompanyResponsibilities() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsPensionProtectionCompanyResponsibilities",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsPensionProtectionCompanyResponsibilities();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBpjsPensionProtectionEmployeeResponsibilities() {
  const { t } = useTranslation();
  return useQuery(
    "bpjsPensionProtectionEmployeeResponsibilities",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBpjsPensionProtectionEmployeeResponsibilities();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useGenders() {
  const { t } = useTranslation();
  return useQuery(
    "genders",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getGenders();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useMaritalStatuses() {
  const { t } = useTranslation();
  return useQuery(
    "maritalStatuses",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getMaritalStatuses();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useBloodTypes() {
  const { t } = useTranslation();
  return useQuery(
    "bloodTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getBloodTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useReligions() {
  const { t } = useTranslation();
  return useQuery(
    "religions",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getReligions();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useEducationLevels() {
  const { t } = useTranslation();
  return useQuery(
    "educationLevels",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getEducationLevels();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useUnpaidLeaveDeductionTypes() {
  const { t } = useTranslation();
  return useQuery(
    "unpaidLeaveDeductionTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getUnpaidLeaveDeductionTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useUnpaidLeaveDeductionNonFixedTypes() {
  const { t } = useTranslation();
  return useQuery(
    "unpaidLeaveDeductionNonFixedTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getUnpaidLeaveDeductionNonFixedTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useApprovalTypes() {
  const { t } = useTranslation();
  return useQuery(
    "approvalTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getApprovalTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useApprovalUserTypes() {
  const { t } = useTranslation();
  return useQuery(
    "approvalUserTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getApprovalUserTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useApprovalStageTypes() {
  const { t } = useTranslation();
  return useQuery(
    "approvalStageTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getApprovalStageTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useCitizenships() {
  const { t } = useTranslation();
  return useQuery(
    "citizenships",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getCitizenships();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useIdCardTypes() {
  const { t } = useTranslation();
  return useQuery(
    "idCardTypes",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getIdCardTypes();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useTaxPayerStatuses() {
  const { t } = useTranslation();
  return useQuery(
    "taxpayerStatuses",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaxpayerStatuses();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function useApprovalStatuses() {
  const { t } = useTranslation();
  return useQuery(
    "approvalStatuses",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getApprovalStatuses();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}

export function usePayrollFormulas() {
  const { t } = useTranslation();
  return useQuery(
    "payrollFormulas",
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getPayrollFormulas();
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      onError: (error) =>
        message.error(
          error?.message || t("error.failed_to_load_data_from_server")
        ),
    }
  );
}
