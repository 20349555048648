// import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
// import generatePicker from "antd/es/date-picker/generatePicker";
// import "antd/es/date-picker/style/index";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useSettingSelector } from "hooks/useSelector";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { localeSelector } from "redux/selectors";

import id from "antd/es/date-picker/locale/id_ID";
import en from "antd/es/date-picker/locale/en_US";

// const DayjsDatePicker = generatePicker(dayjsGenerateConfig);

const { RangePicker: RangeAntd } = DatePicker;

const formattedID = {
  ...id,
  lang: {
    ...id?.lang,
    shortWeekDays: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
  },
};

const RangeDatePicker = (props) => {
  const { t } = useTranslation();
  const settings = useSettingSelector();
  const localeConfig = useSelector(localeSelector);
  const locale = localeConfig === "en-US" ? en : formattedID;

  const ranges = useMemo(() => {
    return {
      [t("date.yesterday")]: [
        dayjs().subtract(1, "day"),
        dayjs().subtract(1, "day"),
      ],
      [t("date.today")]: [dayjs(), dayjs()],
      [t("date.this_month")]: [
        dayjs().startOf("month"),
        dayjs().endOf("month"),
      ],
      [t("date.last_month")]: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
      [t("date.last_7_days")]: [dayjs().subtract(7, "days"), dayjs()],
      [t("date.last_30_days")]: [dayjs().subtract(30, "days"), dayjs()],
      [t("date.this_year")]: [dayjs().startOf("year"), dayjs().endOf("year")],
      [t("date.last_year")]: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    };
  }, [t]);

  return (
    <RangeAntd
      format={
        settings?.app_date_format ? settings.app_date_format : "DD/MM/YYYY"
      }
      locale={{ ...locale, lang: { ...locale.lang, ok: t("button.save") } }}
      presets={ranges}
      className="min-w-[350px]"
      {...props}
    />
  );
};

export default RangeDatePicker;
